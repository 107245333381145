import React from 'react';
import WebsiteLayout from "../components/website/Layout"
import MainContent from "../components/website/MainContent"
import axios from "axios"
import Head from "next/head"

function Index({ count }) {
	return (
		<>
			<Head>
				<meta property="og:title" key='ogtitle' content="זמן טוב - אפליקציית ניהול תורים ולקוחות לעסק שלך" />
				<meta property="og:description" key='ogdescription'
					content="האפליקציה המתקדמת ביותר לניהול תורים למספרות, קוסמטיקאיות, מאמני כושר ועוד. הרשמו עכשיו!" />
				<meta property="og:url" key='ogurl' content={`https://zmantov.co.il/`} />
				<meta property="og:image" key='ogimage' content={'https://zmantov.co.il/img/cover-image.png'} />
			</Head>

			<MainContent businessesCount={count} />
		</>
	)
}

export async function getServerSideProps() {
	let count
	try {
		const { data } = await axios.get(`${process.env.NEXT_PUBLIC_API_BASE}/api/businessesCount/`)
		count = data?.count || 4300
	} catch (err) {
		count = 4300
	}
	return {
		props: { count }
	}
}

Index.Layout = WebsiteLayout;
export default Index
